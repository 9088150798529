import React from 'react'
import { graphql } from 'gatsby'
import PostTemplateDetails from '../components/PostTemplateDetails'
import Page from '../components/Page'
import MainContent from '../components/MainContent'

class PostTemplate extends React.Component {
  render() {
    return (
      <Page {...this.props}>
        <MainContent>
          <PostTemplateDetails {...this.props} />
        </MainContent>
      </Page>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    ...siteQuery
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        tagSlugs
      }
      frontmatter {
        title
        tags
        date
        description
      }
    }
  }
`
